import { graphql } from "gatsby"
import React from "react"
import Seo from "../components/seo"
import { LanguageContext } from "../context/LanguageContext"
import ReactHtmlParser from "react-html-parser"
import { Media } from "reactstrap"
import Image from "../components/Image"
import LinkItem from "../components/LinkItem"

export const query = graphql`
  query NotFoundQuery {
    allGraphCmsPage(filter: { slug: { eq: "404" } }) {
      nodes {
        locale
        title
        header {
          title
          subTitle
          description {
            html
          }
          asset {
            localFile {
              publicURL
            }
          }
          particleEffect
          displayBackground
          titleBackgroundEffect
          linkItems {
            text
            link
            isInternal
            buttonTheme
            outline
            type
            image {
              localFile {
                publicURL
              }
            }
            imagePosition
          }
        }
      }
    }
  }
`

const NotFoundPage = ({ data }) => {
  const { language } = React.useContext(LanguageContext)

  const translationObject = data.allGraphCmsPage.nodes.find(
    obj => obj.locale === language
  )

  const defaultObject = data.allGraphCmsPage.nodes[0]

  return (
    <div className="PageNotFound">
      <Seo title="404: Not found" />
      <div className="PageNotFound__content">
        <Media className="MediaItem">
          <Media body className={`MediaItem__body d-flex align-items-center`}>
            <div>
              <h1 className="PageNotFound__content__title">
                {translationObject.header.title}
              </h1>
              <h3 className="PageNotFound__content__subtitle">
                {translationObject.header.subTitle}
              </h3>
              <div className="PageNotFound__content__description">
                {ReactHtmlParser(defaultObject.header.description.html)}
              </div>
              <LinkItem
                linkItemData={translationObject.header.linkItems[0]}
                defaultLinkItemData={defaultObject.header.linkItems[0]}
              />
            </div>
          </Media>
          <Media right className="MediaItem__image">
            <Media
              object
              tag={Image}
              src={defaultObject.header.asset.localFile.publicURL}
            />
          </Media>
        </Media>
      </div>
    </div>
  )
}

export default NotFoundPage
